<template>
  <div class="home">
    <Banner />
    <div class='backup-domain'>
      <div class='domain-column'>
        备用域名1
        <a href='https://bwfw1881.net'>
          bwfw1881.net
        </a>
      </div>
      <div class='domain-column'>
        备用域名2
        <a href='https://bwfw1882.net'>
          bwfw1882.net
        </a>
      </div>
    </div>
    <div class="tab">
      <div class="item" :class="selectCountry == 0 && 'active'" @click="selectCountry = 0">全部彩种</div>
      <div class="item" :class="selectCountry == 1 && 'active'" @click="selectCountry = 1">全国彩</div>
      <div class="item" :class="selectCountry == 5 && 'active'" @click="selectCountry = 5">英国天天彩</div>
      <div class="item" :class="selectCountry == 2 && 'active'" @click="selectCountry = 2">英国彩</div>
      <div class="item" :class="selectCountry == 3 && 'active'" @click="selectCountry = 3">澳洲彩</div>
      <div class="item" :class="selectCountry == 4 && 'active'" @click="selectCountry = 4">极速彩</div>
    </div>
    <div class="content">
      <LotteryHomeCard v-for="(item, key, index) in LotteryList" :key="key" :lotteryCode="key" v-if="readMoreCount-1 >= index" @openVedio="openVedio"/>
    </div>
    <div v-if="showVedio">
        <h5VideoLive @closeVedio="closeVedio" :lotteryType="clickVedioLotteryType" :lotteryInfo="clickVedioLotteryinfo"></h5VideoLive>
    </div>
  </div>
</template>
<script>
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import { mapState, mapGetters, mapActions } from 'vuex';
import LotteryHomeCard from "@/components/H5/Lottery_Home_Card.vue";
import Banner from "@/components/H5/Banner.vue";
import h5VideoLive from "@/components/live_stream/h5VideoLive.vue";
export default {
  name: 'Home',
  components: {
    LotteryHomeCard,
    Banner,
    h5VideoLive
  },
  data() {
    return {
        readMoreCount: 30,
        selectCountry: 0, //0:ALL  1:CN  2:GB  3:AU
        homeLotteryConfig: lotteryConfig,
        showVedio: false,
        clickVedioLotteryType: '',
        clickVedioLotteryinfo: '',
    }
  },
  computed: {
    ...mapState([
      "baseInfo"
    ]),
    LotteryList:function(){

      if(this.selectCountry==0)
      {
        return  lotteryConfig;
      }
      if(this.selectCountry==1){
        return Object.filter(lotteryConfig, (item)=> item.country=='CN' );
      }
      if(this.selectCountry==2){       
        return Object.filter(lotteryConfig, (item)=> item.country=='GB' );
      }
      if(this.selectCountry==3){
        return Object.filter(lotteryConfig, (item)=> item.country=='AU' );
      }
      if(this.selectCountry==4){
        return Object.filter(lotteryConfig, (item)=> !item.country );
      }
      if(this.selectCountry==5){
        return Object.filter(lotteryConfig, (item)=> item.country=='GBT' );
      }  
    }
  },
   watch: {
    '$route': {
                handler: function(to, from) {
                this.currentHistoryName = to.params.name;
                this.lotteryUtil.changeLottery();
                },
                immediate: true
            } 
  },
    methods: {
        addReadMoreCount () {
            this.readMoreCount+=3;
        },
        removeReadMoreCount(){
            this.readMoreCount-=3;
            if(this.readMoreCount<=0){
                this.readMoreCount=3;
            }
        },
        openVedio(lotteryCode, lotteryInfo) {
            if(lotteryCode == undefined || lotteryInfo == undefined || lotteryInfo.last_period == undefined){
                return false;
            } else {
                this.clickVedioLotteryType = lotteryCode;
                this.clickVedioLotteryinfo = lotteryInfo;
                this.showVedio = true;
            }
        },
        closeVedio() {
            this.showVedio = false;
        },
    },
}
</script>
<style scoped lang="scss">
.home{
  .tab{
    display: flex;
    height: 40px;
    justify-content: space-around;
    align-items: stretch;
    background-color: #fff;
    border-bottom: 1px solid #cfcfcf;
    overflow-x:auto;
    .item{
      flex:1;
      text-align:center;
      font-size: 12px;
      font-weight: bold;
      color: #7e7e7e;
      border-bottom: 2px solid transparent;
      display:flex;
      align-items:center;
      display: -webkit-flex;
      -webkit-align-items: center;
      justify-content: center;
      &.active{
        color: #c19162;
        background-color: #f6f5f5;
        border-bottom: 2px solid #c19162;
      }
    }
  }

  .content{
    padding: 10px;
    padding-bottom: 62px;
  }
}
.backup-domain{
  background:#001236;
  color:#7ab9ff;
  display:flex;
  justify-content: center;
  height:34px;
  align-items: center;
  font-style: italic;
  font-weight:bold;
  font-size:12px;
  gap:5px;
  @media(min-width:400px){
    font-size:14px;
  }
  .domain-column{
    position:relative;
    &:first-child{padding-right:9px;}
    &:first-child:after{
      position:absolute;
      content:'';
      display:block;
      top:-6px;
      width:1px;
      height:26px;
      background:#80899b;
      right:0;
      z-index:1;
    }
  }
  a{color:#fff;text-decoration: underline;margin-left:4px;}
}
</style>