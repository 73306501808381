<template>
<div>
    <div class="banner" >
    </div>
  <div class='marquee-wrapper'>

  <marquee class="info" direction="left"><span>最新公告： 尊敬的用户，百万富翁资讯网拥有世界各地的彩票！</span></marquee>
  </div>

  </div>
</template>

<script>
    export default {
        name: 'Banner',
        props: {
            msg: String
        },
        methods: {

        },
    }
</script>

<style scoped lang="scss">
    .banner {
      background-image: url('~@/img/bn.jpg');
      background-size: cover;
      background-position: center;
      position:relative;
      width: 100%;
      height:128px;
      z-index: 0;
      margin-top:50px;
    }
.marquee-wrapper{
  position:relative;
  height:22px;
}
    marquee{
      position:absolute;
      width:100%;
      border-top:1px solid #595858;
      padding: 5px 5px 5px 0;
      font-size:10px;
      font-weight:100;
      color: #ffe6cc;
      background: #001236;
      &:before, &:after{
        position:absolute;
        content:'';
        display:block;
        width:60px;
        height:100%;
        z-index:2;
        top:0;
      }
      &:before{
        left:0;
        background: url('~@/img/H5/volume.png') no-repeat 20px, linear-gradient(to right, rgba(0,18,54,1) 60%,rgba(0,18,54,0) 100%);
      }
      &:after{
        right:-15px;
        background: linear-gradient(to left, rgba(0,18,54,1) 10%,rgba(0,18,54,0) 100%);
      }
    }
</style>