<template>
    <div class="table_infor">
        <div class="lottery_sx" v-if="lastperiod.display_info">
            <div v-for="(item,index) in lastperiod.display_info.zodiac.slice(0, 6)"
                 :key="index"
                 class="item">
              {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{lastperiod.display_info.zodiac[lastperiod.display_info.zodiac.length-1] }}</div>
        </div>
        <div class="lottery_wx" v-if="lastperiod.display_info">
            <div v-for="(item,index) in lastperiod.display_info.five_elements.slice(0, 6)"
                 :key="index"
                 class="item">
              {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{lastperiod.display_info.five_elements[lastperiod.display_info.five_elements.length-1] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lastperiod:Object | {}
    }
}
</script>
<style lang="scss" scoped>
.table_infor{
    margin-top: 5px;
    width: 50%;
    .lottery_sx,.lottery_wx{
      color: #646363;
      display:grid;
      grid-template-columns: repeat(11, 1fr);
     
        .item{
          text-align: center;
          font-size: 12px;
          width: 21px;
          margin: 2px 0;
        }
    }
     .lottery_wx{
        color: #df0d15;
        
      }
  .add{
    width:6px;
    margin:0 5px;
  }
}
</style>